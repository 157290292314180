import { faChevronRight } from "@fortawesome/pro-light-svg-icons/faChevronRight";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import { useNavigation } from "@react-navigation/core";
import { CompositeNavigationProp } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import Constants from "expo-constants";
import { getLocales } from "expo-localization";
import countries from "i18n-iso-countries";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Platform, Pressable, TextInput, View } from "react-native";
import { Button, Input, Text } from "react-native-elements";
import validator from "validator";
import { ResultType } from "../components/ActionResultComponent";
import CountrySelectionComponent from "../components/CountrySelectionComponent";
import HyperlinkedTextComponent from "../components/HyperlinkedTextComponent";
import NameDataComponent from "../components/NameDataComponent";
import PasswordToggleEyeComponent from "../components/PasswordToggleEyeComponent";
import ScrollViewComponent from "../components/ScrollViewComponent";
import { isManagedException } from "../errors/ApplicationBaseError";
import useReCaptchaToken from "../hooks/ReCaptchaHook";
import { RootStackParamsList } from "../navigation";
import { AuthenticationStackParamsList } from "../navigation/AuthenticationStackNavigation";
import i18n from "../providers/i18n";
import { useRegisterAsync } from "../services/AuthenticationService";
import { IRegisterInfo } from "../services/clients/PlatformClient";
import {
  colors,
  cornerRadius,
  largeInputStyle,
  largePrimaryRoundedButtonStyle,
  pressableStyle,
  spacings,
  typographies
} from "../styles/Styles";
import { isMobilePhone } from "../utils/PhoneValidator";

export default function RegisterScreen() {
  const registerMutation = useRegisterAsync();
  const { executeRecaptcha } = useReCaptchaToken("register");

  const navigation =
    useNavigation<
      CompositeNavigationProp<
        StackNavigationProp<AuthenticationStackParamsList, "register">,
        StackNavigationProp<RootStackParamsList>
      >
    >();
  const { t } = useTranslation();
  const locale = useMemo(() => getLocales()[0], []);
  const [firstName, setFirstName] = useState<string | undefined>();
  const [lastName, setLastName] = useState<string | undefined>();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordSecureTextEntry, setPasswordSecureTextEntry] = useState(true);
  const [confirmPasswordSecureTextEntry, setConfirmPasswordSecureTextEntry] =
    useState(true);
  const [countryCode, setCountryCode] = useState<string | undefined>(
    Platform.OS !== "web" ? locale.regionCode?.trim() || undefined : undefined
  );
  const [displayCountrySelection, setDisplayCountrySelection] = useState(false);

  const [formFieldsValidation, setFormFieldsValidation] = useState({
    emailIsValid: true,
    phoneNumberIsValid: true,
    passwordMatchLength: true,
    confirmPasswordMatchLength: true,
    confirmPasswordEquality: true,
    passwordMatchAlphanumeric: true
  });

  useEffect(() => {
    const unsubscribe = navigation.addListener("beforeRemove", (e) => {
      // If already has been prevented, we need to dispatch the blocked action
      if (e.defaultPrevented) {
        navigation.dispatch(e.data.action);
        return;
      }

      // If we are not in the country selection, we want to go back
      if (!displayCountrySelection) {
        return;
      }

      e.preventDefault();
      setDisplayCountrySelection(false);
    });
    return unsubscribe;
  }, [displayCountrySelection, navigation]);

  const countryName = useMemo(() => {
    if (!countryCode) return undefined;

    const language = i18n.language.substring(0, 2);
    return countries.getName(countryCode, language);
  }, [countryCode, i18n.language]);

  const phoneRef = useRef<TextInput>(null);
  const emailRef = useRef<TextInput>(null);
  const passwordRef = useRef<TextInput>(null);
  const confirmPasswordRef = useRef<TextInput>(null);

  const isFormValid =
    firstName &&
    lastName &&
    email &&
    password &&
    confirmPassword &&
    countryCode &&
    formFieldsValidation.phoneNumberIsValid &&
    formFieldsValidation.emailIsValid &&
    formFieldsValidation.passwordMatchLength &&
    formFieldsValidation.passwordMatchAlphanumeric &&
    formFieldsValidation.confirmPasswordMatchLength &&
    formFieldsValidation.confirmPasswordEquality;

  if (displayCountrySelection) {
    return (
      <CountrySelectionComponent
        defaultCountryCode={countryCode}
        onCountrySelection={(value) => {
          setCountryCode(value);
          setDisplayCountrySelection(false);
        }}
      />
    );
  }

  const minPasswordLength = 6;

  return (
    <ScrollViewComponent>
      <Text
        style={[
          typographies.body,
          {
            textAlign: "center",
            marginBottom: spacings.lg
          }
        ]}>
        {t(
          "RegisterScreenMessage",
          "Sign up on SECANDA to be able to manage your different accounts"
        )}
      </Text>

      <NameDataComponent
        defaultFirstName={firstName}
        defaultLastName={lastName}
        onFirstNameChanged={setFirstName}
        onLastNameChanged={setLastName}
        onSubmitCallback={() => phoneRef.current?.focus()}
      />

      <Pressable
        style={({ pressed }) => [
          pressed && pressableStyle.pressed,
          {
            backgroundColor: colors.background.light,
            height: largeInputStyle.container.height,
            borderRadius: cornerRadius.md,
            paddingHorizontal: spacings.lg,
            paddingVertical: spacings.sm,
            width: "100%",
            flexDirection: "row",
            alignItems: "center",
            marginTop: spacings.lg
          }
        ]}
        onPress={() => setDisplayCountrySelection(true)}>
        <Text
          style={[
            typographies.subtitle,
            {
              flex: 1,
              ...(!countryCode && {
                color: colors.text.placeholder
              })
            }
          ]}>
          {countryName ?? t("CountryPlaceholder", "Select your country *")}
        </Text>

        <FontAwesomeIcon
          icon={faChevronRight}
          color={countryCode ? colors.text.primary : colors.text.placeholder}
        />
      </Pressable>
      <Input
        inputMode="tel"
        inputContainerStyle={largeInputStyle.inputContainer}
        containerStyle={[
          largeInputStyle.container,
          {
            marginBottom: spacings.lg,
            marginTop: spacings.lg,
            borderColor: !formFieldsValidation.phoneNumberIsValid
              ? colors.danger
              : undefined,
            borderWidth: !formFieldsValidation.phoneNumberIsValid
              ? cornerRadius.xxs
              : undefined
          }
        ]}
        ref={phoneRef}
        returnKeyType="next"
        onSubmitEditing={() => {
          emailRef.current?.focus();
        }}
        inputStyle={largeInputStyle.input}
        placeholder={t("RegisterScreenPhonePlaceHolder", "Mobile phone number")}
        autoCorrect={false}
        errorMessage={
          !formFieldsValidation.phoneNumberIsValid
            ? t(
                "RegisterScreenPhoneNotValid",
                "Mobile phone number is not valid"
              )
            : undefined
        }
        onBlur={() =>
          setFormFieldsValidation({
            ...formFieldsValidation,
            phoneNumberIsValid: !validator.isEmpty(phoneNumber)
              ? isMobilePhone(phoneNumber)
              : true
          })
        }
        placeholderTextColor={colors.text.placeholder}
        onChangeText={setPhoneNumber}
        value={phoneNumber}></Input>

      <Input
        inputMode="email"
        inputContainerStyle={largeInputStyle.inputContainer}
        autoCapitalize="none"
        containerStyle={[
          largeInputStyle.container,
          {
            marginBottom: spacings.lg,
            borderColor: !formFieldsValidation.emailIsValid
              ? colors.danger
              : undefined,
            borderWidth: !formFieldsValidation.emailIsValid
              ? cornerRadius.xxs
              : undefined
          }
        ]}
        ref={emailRef}
        returnKeyType="next"
        onSubmitEditing={() => {
          passwordRef.current?.focus();
        }}
        inputStyle={largeInputStyle.input}
        placeholder={t("RegisterScreenEmailPlaceHolder", "Email *")}
        autoCorrect={false}
        placeholderTextColor={colors.text.placeholder}
        errorMessage={
          !formFieldsValidation.emailIsValid
            ? t("RegisterScreenEmailNotValid", "Email is not valid")
            : undefined
        }
        onBlur={() =>
          setFormFieldsValidation({
            ...formFieldsValidation,
            emailIsValid: validator.isEmail(email)
          })
        }
        onChangeText={setEmail}
        value={email}></Input>

      <Input
        inputContainerStyle={largeInputStyle.inputContainer}
        containerStyle={[
          largeInputStyle.container,
          {
            marginBottom: spacings.lg,
            borderColor:
              !formFieldsValidation.passwordMatchLength ||
              !formFieldsValidation.passwordMatchAlphanumeric
                ? colors.danger
                : undefined,
            borderWidth:
              !formFieldsValidation.passwordMatchLength ||
              !formFieldsValidation.passwordMatchAlphanumeric
                ? cornerRadius.xxs
                : undefined
          }
        ]}
        ref={passwordRef}
        returnKeyType="next"
        onSubmitEditing={() => {
          confirmPasswordRef.current?.focus();
        }}
        inputStyle={largeInputStyle.input}
        placeholder={t("RegisterScreenPasswordPlaceHolder", "Password *")}
        autoCorrect={false}
        placeholderTextColor={colors.text.placeholder}
        autoCapitalize="none"
        rightIcon={
          <PasswordToggleEyeComponent
            isSecure={passwordSecureTextEntry}
            onPress={() => setPasswordSecureTextEntry(!passwordSecureTextEntry)}
          />
        }
        secureTextEntry={passwordSecureTextEntry}
        errorMessage={
          !formFieldsValidation.passwordMatchLength
            ? t(
                "RegisterScreenPasswordNotMatchLength",
                "Minimum length 6 characters."
              )
            : !formFieldsValidation.passwordMatchAlphanumeric
            ? t(
                "RegisterScreenPasswordNotMatchAlphanumeric",
                "Alphanumeric characters required."
              )
            : undefined
        }
        onBlur={() => validatePassword()}
        onChangeText={setPassword}
        value={password}></Input>

      <Input
        containerStyle={[
          largeInputStyle.container,
          {
            borderColor:
              !formFieldsValidation.confirmPasswordMatchLength ||
              !formFieldsValidation.confirmPasswordEquality
                ? colors.danger
                : undefined,
            borderWidth:
              !formFieldsValidation.confirmPasswordMatchLength ||
              !formFieldsValidation.confirmPasswordEquality
                ? cornerRadius.xxs
                : undefined
          }
        ]}
        inputContainerStyle={largeInputStyle.inputContainer}
        inputStyle={largeInputStyle.input}
        rightIcon={
          <PasswordToggleEyeComponent
            isSecure={confirmPasswordSecureTextEntry}
            onPress={() =>
              setConfirmPasswordSecureTextEntry(!confirmPasswordSecureTextEntry)
            }
          />
        }
        secureTextEntry={confirmPasswordSecureTextEntry}
        placeholder={t(
          "RegisterScreenConfirmPasswordPlaceHolder",
          "Confirm password *"
        )}
        ref={confirmPasswordRef}
        autoCapitalize="none"
        autoCorrect={false}
        placeholderTextColor={colors.text.placeholder}
        onChangeText={setConfirmPassword}
        onBlur={() => validateConfirmPassword()}
        onSubmitEditing={registerAsync}
        value={confirmPassword}
        errorMessage={
          !formFieldsValidation.confirmPasswordMatchLength
            ? t(
                "RegisterScreenPasswordNotMatchLength",
                "Minimum length 6 characters."
              )
            : !formFieldsValidation.confirmPasswordEquality
            ? t(
                "RegisterScreenConfirmPasswordNotMatch",
                "Passwords do not match."
              )
            : undefined
        }></Input>

      <View
        style={{
          marginTop: spacings.xxl,
          alignItems: "center"
        }}>
        <HyperlinkedTextComponent
          containerStyle={{
            alignItems: "center",
            justifyContent: "center"
          }}
          firstText={t(
            "RegisterScreenConditionTitle",
            "By pressing Sign up, you accept our"
          )}
          firstHyperlinkText={t(
            "RegisterScreenTermsAndConditions",
            "Terms & Conditions"
          )}
          onFirstHyperlinkCallback={() =>
            navigation.navigate("termsAndConditions")
          }
          secondText={t("RegisterScreenAndLabel", "and")}
          secondHyperlinkText={t(
            "RegisterScreenPrivacyPolicy",
            "Privacy policy"
          )}
          onSecondHyperlinkCallback={() => navigation.navigate("privacyPolicy")}
        />

        <Button
          title={t("RegisterScreenRegisterButton", "Sign up")}
          disabled={!isFormValid || registerMutation.isLoading}
          onPress={registerAsync}
          titleStyle={[largePrimaryRoundedButtonStyle.title, { flex: 1 }]}
          containerStyle={[
            largePrimaryRoundedButtonStyle.container,
            {
              marginTop: spacings.md
            }
          ]}
          loadingStyle={largePrimaryRoundedButtonStyle.loading}
          disabledStyle={largePrimaryRoundedButtonStyle.disabled}
          buttonStyle={largePrimaryRoundedButtonStyle.button}
          loading={registerMutation.isLoading}></Button>
      </View>
    </ScrollViewComponent>
  );

  function validatePassword() {
    setFormFieldsValidation({
      ...formFieldsValidation,
      passwordMatchLength: validator.isLength(password, {
        min: minPasswordLength
      }),
      passwordMatchAlphanumeric: validator.matches(
        password,
        "^(?=.*\\d)(?=.*[a-zA-Z]).{0,}$"
      )
    });
  }

  function validateConfirmPassword() {
    setFormFieldsValidation({
      ...formFieldsValidation,
      confirmPasswordMatchLength: validator.isLength(confirmPassword, {
        min: minPasswordLength
      }),
      confirmPasswordEquality: validator.equals(confirmPassword, password)
    });
  }

  async function registerAsync() {
    try {
      if (!isFormValid) {
        return;
      }

      const registerInfo: IRegisterInfo = {
        firstName: firstName?.trim(),
        lastName: lastName?.trim(),
        phoneNumber: phoneNumber?.trim() || undefined,
        languageCode: i18n.language?.trim() || undefined,
        countryCode: countryCode?.trim() || undefined,
        email: email?.trim(),
        password: password,
        callbackUrl: Constants.expoConfig?.extra?.validateEmailCallback,
        legalTermsVersion: Constants.expoConfig?.extra?.legalTermsVersion
      };

      const recaptchaToken = await executeRecaptcha();
      await registerMutation.mutateAsync({
        registerInfo,
        recaptchaToken
      });

      navigation.navigate("resendEmailValidation", {
        email: registerInfo.email
      });
    } catch (e: any) {
      const managedException = isManagedException(e);
      if (managedException) {
        navigation.navigate("registerResult", {
          type: ResultType.Error,
          description: managedException.message
        });
        return;
      }

      let errorDescription: string;
      switch (e?.status) {
        case 409:
          errorDescription = t(
            "RegisterScreenRegisterAccountEmailExists",
            "An account with the email provided already exists."
          );
          break;

        default:
          errorDescription = t(
            "RegisterScreenRegisterUnknownError",
            "Internal server error"
          );
          break;
      }

      navigation.navigate("registerResult", {
        type: ResultType.Error,
        description: errorDescription
      });
    }
  }
}
