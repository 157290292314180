import { useMutation, useQuery, UseQueryOptions } from "@tanstack/react-query";
import axios, { AxiosInstance } from "axios";
import Constants from "expo-constants";
import {
  AbortPaymentResponse,
  CheckinSessionRequest,
  CheckinSessionResponse,
  ConfirmPaymentResponse,
  ICheckinSessionRequest,
  PaymentStatusResponse,
  SecandaPaymentClient
} from "./clients/SecandaPaymentClient";
import { secandaPaymentStatusQueryKey } from "./QueryService";

export const secandaPaymentClientAxiosInstance: AxiosInstance = axios.create();
const secandaPaymentClient = new SecandaPaymentClient(
  Constants.manifest?.extra?.secandaPaymentAddress,
  secandaPaymentClientAxiosInstance
);

class PaymentDetails {
  //TODO: add the currently not implemented api function for getting amount, etc
  amount: number;
  logo: string;
  currency: string;
  beneficiary: string;

  constructor() {
    this.amount = 10;
    this.logo = "https://madsense.com/wp-content/uploads/madsense-256.png";
    this.currency = "CHF";
    this.beneficiary = "Tartare House";
  }
}

async function checkin(token: string): Promise<CheckinSessionResponse> {
  const checkInSessionRequest: ICheckinSessionRequest = {
    token: token
  };

  return await secandaPaymentClient.checkin(
    new CheckinSessionRequest(checkInSessionRequest)
  );
}

async function payment(id: string): Promise<PaymentStatusResponse> {
  return await secandaPaymentClient.payment(id);
}

async function confirmPayment(id: string): Promise<ConfirmPaymentResponse> {
  return await secandaPaymentClient.confirm(id);
}

async function abortPayment(id: string): Promise<AbortPaymentResponse> {
  return await secandaPaymentClient.abort(id);
}

export function useSessionCheckIn() {
  return useMutation(({ token }: { token: string }) => checkin(token));
}

export function usePaymentStatus(
  sessionId: string,
  options?: UseQueryOptions<
    PaymentStatusResponse,
    unknown,
    PaymentStatusResponse,
    string[]
  >
) {
  return useQuery(
    [secandaPaymentStatusQueryKey, sessionId],
    () => payment(sessionId),
    {
      ...options
    }
  );
}

export function useConfirmPayment() {
  return useMutation((id: string) => confirmPayment(id));
}

export function useAbortPayment() {
  return useMutation((id: string) => abortPayment(id));
}

// TODO: Temporary until we get an API method which manages the payment details. Then make use of useQuery()
export function getPaymentDetails(): PaymentDetails {
  return new PaymentDetails();
}
