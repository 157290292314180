import { faPaperPlane } from "@fortawesome/pro-light-svg-icons/faPaperPlane";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import {
  NavigationProp,
  RouteProp,
  useNavigation,
  useRoute
} from "@react-navigation/native";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { SafeAreaView, View } from "react-native";
import { Button, Text } from "react-native-elements";
import { isManagedException } from "../errors/ApplicationBaseError";
import { AuthenticationStackParamsList } from "../navigation/AuthenticationStackNavigation";
import { useResendValidationEmail } from "../services/IdentifiersService";
import { toastError, toastSuccess } from "../services/ToastService";
import {
  colors,
  containerStyle,
  iconSizes,
  largePrimaryOutlineRoundedButtonStyle,
  largePrimaryRoundedButtonStyle,
  spacings,
  typographies
} from "../styles/Styles";

export default function ResendEmailValidationScreen() {
  const initialCountdownValue = 60;
  const { t } = useTranslation();
  const [countdown, setCountdown] = useState(initialCountdownValue);
  const [intervalId, setIntervalId] = useState<NodeJS.Timer>();
  const countdownRef = useRef(countdown);
  const resendValidationEmailMutation = useResendValidationEmail();
  const route =
    useRoute<
      RouteProp<AuthenticationStackParamsList, "resendEmailValidation">
    >();
  const navigation =
    useNavigation<
      NavigationProp<AuthenticationStackParamsList, "resendEmailValidation">
    >();

  useEffect(() => {
    startCountdown();
  }, []);

  function startCountdown() {
    const newIntervalId = setInterval(() => {
      countdownRef.current -= 1;
      if (countdownRef.current < 0) {
        clearInterval(newIntervalId);
      } else {
        setCountdown(countdownRef.current);
      }
    }, 1000);
    setIntervalId(newIntervalId);
  }

  function resetCountdown() {
    clearInterval(intervalId);
    setCountdown(initialCountdownValue);
    countdownRef.current = initialCountdownValue;
    startCountdown();
  }

  async function resendEmail() {
    try {
      if (!route.params.email) {
        return;
      }

      await resendValidationEmailMutation.mutateAsync(route.params.email);
      toastSuccess(
        t("AlertSuccessTitle", "Success!"),
        t(
          "IdentifierResendValidationEmailSuccessMessage",
          "The validation email has been sent successfully"
        )
      );
    } catch (e: any) {
      const managedException = isManagedException(e);
      if (managedException) {
        toastError(managedException.title, managedException.message);
        return;
      }

      toastError(
        t("AlertErrorTitle", "Error"),
        t(
          "IdentifierResendValidationEmailErrorMessage",
          "An error occurred when sending the validation email"
        )
      );
    } finally {
      resetCountdown();
    }
  }

  return (
    <SafeAreaView style={{ flex: 1 }}>
      <View
        style={[
          {
            paddingVertical: spacings.xl,
            flex: 1
          },
          containerStyle.container
        ]}>
        <View
          style={{
            alignItems: "center",
            paddingTop: 150,
            paddingHorizontal: spacings.md
          }}>
          <FontAwesomeIcon
            icon={faPaperPlane}
            size={iconSizes.xxxxl}
            style={{ color: colors.text.primary }}
          />

          <Text
            style={[
              typographies.subtitle,
              {
                marginTop: spacings.xl,
                marginBottom: spacings.lg,
                textAlign: "center"
              }
            ]}>
            {t(
              "ResendEmailValidationScreenSendTitle",
              "We just sent you a confirmation email"
            )}
          </Text>

          <Text
            style={[
              typographies.body,
              {
                textAlign: "center"
              }
            ]}>
            {t(
              "ResendEmailValidationScreenDescription",
              "Please check your mailbox and click on the confirmation link to validate your email"
            )}
          </Text>
        </View>

        <View
          style={{
            marginHorizontal: spacings.lg,
            flex: 1,
            flexDirection: "column",
            justifyContent: "flex-end"
          }}>
          {countdown !== 0 && (
            <Text style={[typographies.small, { textAlign: "center" }]}>
              {t(
                "ResendEmailValidationScreenResendCountdown",
                "{{countdown}} secs to resend again",
                { countdown: countdown }
              )}
            </Text>
          )}

          <Button
            titleStyle={largePrimaryRoundedButtonStyle.title}
            containerStyle={[
              largePrimaryRoundedButtonStyle.container,
              {
                marginBottom: spacings.lg,
                marginTop: spacings.md
              }
            ]}
            disabled={
              countdown !== 0 || resendValidationEmailMutation.isLoading
            }
            loading={resendValidationEmailMutation.isLoading}
            buttonStyle={largePrimaryRoundedButtonStyle.button}
            title={t("ResendEmailValidationScreenResendButton", "Resend email")}
            onPress={resendEmail}
          />

          <Button
            titleStyle={largePrimaryOutlineRoundedButtonStyle.title}
            buttonStyle={largePrimaryOutlineRoundedButtonStyle.button}
            title={t("ResendEmailValidationScreenLoginButton", "Go to login")}
            onPress={() =>
              navigation.navigate("login", { email: route.params.email })
            }
          />
        </View>
      </View>
    </SafeAreaView>
  );
}
