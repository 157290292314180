import { useMutation } from "@tanstack/react-query";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import React, { useEffect } from "react";
import { useAuth } from "../providers/AuthenticationProvider";
import { ACCESS_TOKEN } from "../providers/IdentityConstants";
import {
  TokenResponse,
  useClientCredentials
} from "../services/AuthenticationService";
import { refreshTokenMutationKey } from "../services/QueryService";
import { secandaPaymentClientAxiosInstance } from "../services/SecandaPaymentService";
import * as SecureStorage from "../services/SecureStorage";
import { platformClientAxiosInstance } from "../services/clients/PlatformClientFactory";

const AuthenticationInterceptor = ({
  children
}: {
  children: React.ReactNode;
}): React.ReactElement => {
  const { signOut, refreshAccessToken } = useAuth();
  const refreshTokenMutation = useMutation(
    [refreshTokenMutationKey],
    refreshAccessToken
  );
  const clientCredentialsMutation = useClientCredentials();

  let clientCredentialsAccessToken: string | null = null;

  useEffect(() => {
    platformClientAxiosInstance.interceptors.request.use(async (request) => {
      const accessToken = await GetAuthorizationHeader();
      if (accessToken) {
        request.headers.Authorization = `Bearer ${accessToken}`;
      }

      return request;
    });

    // Add bearer token on requests to SECANDA payment
    secandaPaymentClientAxiosInstance.interceptors.request.use(
      async (request: AxiosRequestConfig) => {
        const accessToken = await GetAuthorizationHeader();
        if (accessToken) {
          if (!request.headers) request.headers = {};
          request.headers.Authorization = `Bearer ${accessToken}`;
        }

        return request;
      }
    );

    createAuthRefreshInterceptor(
      platformClientAxiosInstance,
      async (failedRequest) => {
        const tokenResponse = await RefreshToken();
        if (tokenResponse?.access_token) {
          failedRequest.response.config.headers[
            "Authorization"
          ] = `Bearer ${tokenResponse?.access_token}`;
        }
      },
      {
        pauseInstanceWhileRefreshing: true
      }
    );
  }, []);

  async function RefreshToken(): Promise<TokenResponse | null> {
    try {
      const response = await refreshTokenMutation.mutateAsync();
      return response;
    } catch (error: any) {
      const status = error.response ? error.response.status : null;
      if (status === 400) {
        await signOut();
      }
      return null;
    }
  }

  async function GetAuthorizationHeader(): Promise<string | null> {
    const accessToken = await SecureStorage.getItemAsync(ACCESS_TOKEN);
    if (accessToken) {
      return accessToken;
    }

    if (!clientCredentialsAccessToken) {
      const clientCredentialsResponse =
        await clientCredentialsMutation.mutateAsync();
      clientCredentialsAccessToken = clientCredentialsResponse?.access_token;
    }
    return clientCredentialsAccessToken;
  }

  return children as React.ReactElement;
};

export default AuthenticationInterceptor;
