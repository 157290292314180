import { createStackNavigator } from "@react-navigation/stack";
import React from "react";
import { useTranslation } from "react-i18next";
import { View } from "react-native";
import PaymentConfirmationScreen from "../screens/PaymentConfirmationScreen";
import PaymentResultScreen from "../screens/PaymentResultScreen";
import PaymentSelectAccountScreen from "../screens/PaymentSelectAcountScreen";
import PayScreen from "../screens/PayScreen";
import { PaymentStatus } from "../services/clients/SecandaPaymentClient";
import { headerStyle } from "../styles/Styles";

export type PaymentConfirmationScreenProps = {
  token: string;
  // TODO: To be check if needed.
  // identifierId: number;
  // affiliationId: number;
  // personId: number;
  // accountId: number;
};

export type PaymentSelectAccountScreenProps = {
  token: string;
};

export type PaymentResultScreenProps = {
  amount: number;
  currency: string;
  resultStatus: PaymentStatus;
};

export type PayStackParamsList = {
  pay: undefined;
  paymentConfirmation: PaymentConfirmationScreenProps;
  paymentResult: PaymentResultScreenProps;
  paymentSelectAccount: PaymentSelectAccountScreenProps;
};

// We need it in order to be able to open a modal from the bottom tab.
export function PayBaseScreen() {
  return <View></View>;
}

const PayStack = createStackNavigator<PayStackParamsList>();

export default function PayStackScreen() {
  const { t } = useTranslation();

  return (
    <PayStack.Navigator screenOptions={{ headerTitleStyle: headerStyle.title }}>
      <PayStack.Screen
        name="pay"
        component={PayScreen}
        options={{
          title: t("PayScreenTitle", "Pay"),
          headerBackTitle: t("CancelBackButton", "Cancel")
        }}
      />
      {
        <PayStack.Screen
          name="paymentSelectAccount"
          component={PaymentSelectAccountScreen}
          options={{
            title: t("PayScreenTitle", "Pay")
          }}
        />
      }
      <PayStack.Screen
        name="paymentConfirmation"
        component={PaymentConfirmationScreen}
        options={{
          title: t("PaymentConfirmationScreenTitle", "Approve payment")
        }}
      />
      <PayStack.Screen
        name="paymentResult"
        component={PaymentResultScreen}
        options={{
          title: t("PaymentResultScreenTitle", "Payment result"),
          headerShown: false
        }}
      />
    </PayStack.Navigator>
  );
}
