import {
  NavigationProp,
  RouteProp,
  StackActions,
  useNavigation,
  useRoute
} from "@react-navigation/native";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ActivityIndicator, ScrollView, View } from "react-native";
import { Text } from "react-native-elements";
import AccountListItemComponent from "../components/AccountListItemComponent";
import {
  PaymentConfirmationScreenProps,
  PayStackParamsList
} from "../navigation/PayStackNavigation";
import { AccountDetail, useAccounts } from "../services/AccountsService";
import { AffiliationStatus } from "../services/clients/PlatformClient";
import { spacings } from "../styles/Constants";
import { containerStyle } from "../styles/Containers";
import { typographies } from "../styles/Fonts";
import { colors } from "../styles/Styles";

export default function PaymentSelectAccountScreen() {
  const accountsQuery = useAccounts();
  const { t } = useTranslation();
  const navigation = useNavigation<NavigationProp<PayStackParamsList>>();
  const route =
    useRoute<RouteProp<PayStackParamsList, "paymentSelectAccount">>();

  useEffect(() => {
    if (accountsQuery.data?.length === 1) {
      const account = accountsQuery.data[0];
      if (account.affiliation.status !== AffiliationStatus.Offline) {
        const params: PaymentConfirmationScreenProps = {
          token: route.params.token
          // TODO: To be checked if needed
          // identifierId: account.identifier.identifierId,
          // affiliationId: account.affiliation.affiliationId,
          // personId: account.person!.personId,
          // accountId: account.account!.accountId
        };
        navigation.dispatch(
          StackActions.replace("paymentConfirmation", params)
        );
      }
    }
  }, [accountsQuery.data]);

  if (accountsQuery.isLoading) {
    return (
      <View style={containerStyle.containerCenter}>
        <ActivityIndicator
          color={colors.primary}
          size="large"></ActivityIndicator>
      </View>
    );
  }

  return (
    <ScrollView
      contentContainerStyle={{
        padding: spacings.lg
      }}>
      <Text
        style={[
          typographies.body,
          {
            marginTop: spacings.sm,
            marginBottom: spacings.md,
            alignSelf: "center"
          }
        ]}>
        {t("SelectAccountForPaymentScreenTitle", "Select account to pay from")}
      </Text>

      <View style={{ width: "100%", flex: 1 }}>
        <DisplayAccounts
          accounts={accountsQuery.data ?? []}
          onPress={accountSelected}></DisplayAccounts>
      </View>
    </ScrollView>
  );

  function accountSelected(account: AccountDetail): void {
    const params: PaymentConfirmationScreenProps = {
      token: route.params.token
      // TODO: To be checked if needed
      // identifierId: account.identifier.identifierId,
      // affiliationId: account.affiliation.affiliationId,
      // personId: account.person!.personId,
      // accountId: account.account!.accountId
    };
    navigation.navigate("paymentConfirmation", params);
  }
}

function DisplayAccounts({
  accounts,
  onPress
}: {
  accounts: AccountDetail[];
  onPress: (account: AccountDetail) => void;
}) {
  if (!accounts.length) {
    return null;
  }

  return (
    <View style={{ width: "100%", flex: 1 }}>
      {accounts.map((item, index) => {
        return (
          <AccountListItemComponent
            key={index}
            account={item}
            onPress={() => onPress(item)}></AccountListItemComponent>
        );
      })}
    </View>
  );
}
