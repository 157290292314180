import { faCheck } from "@fortawesome/pro-light-svg-icons/faCheck";
import { faChevronRight } from "@fortawesome/pro-light-svg-icons/faChevronRight";
import { faEllipsisVertical } from "@fortawesome/pro-light-svg-icons/faEllipsisVertical";
import { faFileContract } from "@fortawesome/pro-light-svg-icons/faFileContract";
import { faGlobe } from "@fortawesome/pro-light-svg-icons/faGlobe";
import { faQuestionCircle } from "@fortawesome/pro-light-svg-icons/faQuestionCircle";
import { faUserShield } from "@fortawesome/pro-light-svg-icons/faUserShield";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import {
  NavigationProp,
  RouteProp,
  useNavigation,
  useRoute
} from "@react-navigation/native";
import { useMutation } from "@tanstack/react-query";
import { Image } from "expo-image";
import { openURL, useURL } from "expo-linking";
import { default as React, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Platform, Pressable, TextInput, View } from "react-native";
import { Button, CheckBox, Input, Text } from "react-native-elements";
import { SafeAreaView } from "react-native-safe-area-context";
import * as DropdownMenu from "zeego/dropdown-menu";
import DropdownMenuComponent from "../components/DropdownMenuComponent";
import { languages } from "../components/LanguageSelectionComponent";
import LocalAuthComponent from "../components/LocalAuthComponent";
import PasswordToggleEyeComponent from "../components/PasswordToggleEyeComponent";
import ScrollViewComponent from "../components/ScrollViewComponent";
import { isManagedException } from "../errors/ApplicationBaseError";
import { RootStackParamsList } from "../navigation";
import { AuthenticationStackParamsList } from "../navigation/AuthenticationStackNavigation";
import { useAuth } from "../providers/AuthenticationProvider";
import i18n from "../providers/i18n";
import { useResendValidationEmail } from "../services/IdentifiersService";
import { configureLocalAuthAsync } from "../services/LocalAuthService";
import { toastError } from "../services/ToastService";
import {
  colors,
  containerStyle,
  cornerRadius,
  iconSizes,
  largeInputStyle,
  largePrimaryOutlineRoundedButtonStyle,
  largePrimaryRoundedButtonStyle,
  pressableStyle,
  primaryLinkButtonStyle,
  spacings,
  typographies
} from "../styles/Styles";

export default function LoginScreen() {
  const isWeb = Platform.OS === "web";

  const loginMutation = useMutation(
    ({
      userEmail,
      userPassword,
      rememberMe
    }: {
      userEmail: string;
      userPassword: string;
      rememberMe: boolean;
    }) => signIn(userEmail, userPassword, rememberMe)
  );
  const resendValidationEmailMutation = useResendValidationEmail();
  const route = useRoute<RouteProp<AuthenticationStackParamsList, "login">>();
  const navigation =
    useNavigation<NavigationProp<AuthenticationStackParamsList>>();
  const { signIn, userIdentifier, accessToken } = useAuth();
  const [userEmail, setUserEmail] = useState(userIdentifier ?? "");
  const [userPassword, setUserPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [passwordSecureTextEntry, setPasswordSecureTextEntry] = useState(true);
  const [returnUrl, setReturnUrl] = useState<string | undefined>();
  const { t } = useTranslation();
  const passwordRef = useRef<TextInput>(null);
  const initialUrl = useURL();

  const isFormValid = userEmail || userPassword;

  // On web, all navigations are deep links. That's why we need to avoid profile, because if we sign out, we go to the login screen (so initial url would be '/profile' in web)
  // Same for '/login'. When we go to for example www.secanda.dev, we are being redirected to login, so initial url is '/login'
  const pathsToAvoidReturnUrl = [
    "/login",
    "/profile",
    "/help-center",
    "/user",
    "/privacy-policy",
    "/terms-and-conditions"
  ];

  useEffect(() => {
    if (
      initialUrl &&
      !pathsToAvoidReturnUrl.some((p) => initialUrl.includes(p))
    ) {
      setReturnUrl(initialUrl);
    }
  }, [initialUrl]);

  useEffect(() => {
    if (route.params?.email) {
      setUserEmail(route.params.email);
    }
  }, [route.params?.email]);

  return (
    <SafeAreaView
      style={[
        containerStyle.container,
        {
          paddingHorizontal: spacings.none,
          flex: 1
        }
      ]}>
      <ScrollViewComponent
        scrollViewBounceVertical={false}
        scrollViewContentContainerStyle={{
          flexGrow: 1,
          paddingVertical: spacings.xxl,
          paddingHorizontal: spacings.lg
        }}>
        <Image
          style={{ alignSelf: "center", width: 250, height: 150 }}
          source={require("../assets/img/secanda-branding.svg")}
          contentFit="contain"
        />

        <DropdownMenuSection />

        <Input
          inputMode="email"
          inputContainerStyle={largeInputStyle.inputContainer}
          containerStyle={[largeInputStyle.container, { marginTop: 70 }]}
          inputStyle={largeInputStyle.input}
          placeholder={t("LoginEmailOrPhone", "Email")}
          autoCorrect={false}
          autoCapitalize="none"
          placeholderTextColor={colors.text.placeholder}
          errorStyle={{ margin: isWeb ? spacings.xs : spacings.none }}
          onChangeText={setUserEmail}
          value={userEmail}
          returnKeyType="next"
          onSubmitEditing={() => {
            passwordRef.current?.focus();
          }}></Input>
        <Input
          inputContainerStyle={largeInputStyle.inputContainer}
          containerStyle={[
            largeInputStyle.container,
            { marginTop: spacings.sm, marginBottom: spacings.md }
          ]}
          ref={passwordRef}
          inputStyle={largeInputStyle.input}
          placeholder={t("LoginPassword", "Password")}
          autoCorrect={false}
          autoCapitalize="none"
          placeholderTextColor={colors.text.placeholder}
          rightIcon={
            <PasswordToggleEyeComponent
              isSecure={passwordSecureTextEntry}
              onPress={() =>
                setPasswordSecureTextEntry(!passwordSecureTextEntry)
              }
            />
          }
          secureTextEntry={passwordSecureTextEntry}
          errorStyle={{ margin: isWeb ? spacings.xs : spacings.none }}
          onChangeText={setUserPassword}
          onSubmitEditing={loginAsync}
          value={userPassword}></Input>
        {isWeb && (
          <CheckBox
            containerStyle={{
              backgroundColor: colors.background.transparent,
              borderWidth: cornerRadius.none
            }}
            checked={rememberMe}
            checkedIcon="check-square"
            checkedColor={colors.primary}
            textStyle={[
              typographies.body,
              { marginLeft: spacings.sm, fontWeight: "normal" }
            ]}
            title={t("LoginRememberMe", "Remember me")}
            onPress={() => setRememberMe(!rememberMe)}></CheckBox>
        )}
        <View
          style={{
            marginHorizontal: spacings.lg,
            alignItems: "center"
          }}>
          <Button
            title={t("LoginScreenSignIn", "Sign in")}
            disabled={!isFormValid || loginMutation.isLoading}
            onPress={loginAsync}
            titleStyle={largePrimaryRoundedButtonStyle.title}
            containerStyle={largePrimaryRoundedButtonStyle.container}
            buttonStyle={largePrimaryRoundedButtonStyle.button}
            loading={loginMutation.isLoading}
            loadingStyle={largePrimaryRoundedButtonStyle.loading}
            disabledStyle={largePrimaryRoundedButtonStyle.disabled}></Button>

          {accessToken && <LocalAuthComponent />}

          <Button
            title={t("LoginForgotPassword", "Forgot password?")}
            type="clear"
            titleStyle={primaryLinkButtonStyle.title}
            onPress={() =>
              navigation.navigate("forgotPassword", { email: userEmail })
            }
            containerStyle={{ marginTop: spacings.lg, width: "auto" }}></Button>
        </View>
        <View
          style={{
            marginHorizontal: spacings.lg,
            flex: 1,
            justifyContent: "flex-end"
          }}>
          <Text
            style={[
              typographies.body,
              { textAlign: "center", marginBottom: spacings.sm }
            ]}>
            {t("LoginNoAccount", "Don't have an account?")}
          </Text>

          <Button
            title={t("LoginSignUp", "Sign up")}
            titleStyle={largePrimaryOutlineRoundedButtonStyle.title}
            onPress={() => navigation.navigate("register")}
            buttonStyle={largePrimaryOutlineRoundedButtonStyle.button}></Button>
        </View>
      </ScrollViewComponent>
    </SafeAreaView>
  );

  async function loginAsync() {
    if (!isFormValid) {
      return;
    }

    try {
      await loginMutation.mutateAsync({
        userEmail,
        userPassword,
        rememberMe: isWeb ? rememberMe : true
      });
      await configureLocalAuthAsync();

      if (returnUrl) {
        await openURL(returnUrl);
      }
    } catch (e: any) {
      const managedException = isManagedException(e);
      if (managedException) {
        toastError(managedException.title, managedException.message);
        return;
      }

      switch (e?.response?.data?.HttpStatusCode) {
        case 403:
          await resendValidationEmailMutation.mutateAsync(userEmail);
          navigation.navigate("resendEmailValidation", { email: userEmail });
          break;
        case 423:
          toastError(
            t("LoginFailedTitle", "Login failed"),
            t(
              "LoginFailedMessageLocked",
              "User locked. Maximum amount of attempts was reached. Please try again later."
            )
          );
          break;
        case 404:
          toastError(
            t("LoginFailedTitle", "Login failed"),
            t(
              "LoginFailedMessageInvalidUserName",
              "Invalid username or password"
            )
          );
          break;
        default:
          toastError(
            t("LoginFailedTitle", "Login failed"),
            t("LoginFailedMessageInternalServerError", "Internal server error")
          );
          break;
      }
    }
  }
}

function DropdownMenuSection() {
  const { t } = useTranslation();
  const navigation = useNavigation<NavigationProp<RootStackParamsList>>();
  const {
    DropdownMenuRoot,
    DropdownMenuTrigger,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuItemTitle,
    DropdownMenuSub,
    DropdownMenuSubTrigger,
    DropdownMenuSubContent,
    DropdownMenuCheckboxItem
  } = DropdownMenuComponent();

  const language =
    languages.find((l) => l.value === i18n.language)?.value ??
    languages[0].value;

  return (
    <View style={{ position: "absolute", right: 0 }}>
      <DropdownMenuRoot>
        <DropdownMenuTrigger>
          <Pressable style={({ pressed }) => pressed && pressableStyle.pressed}>
            <FontAwesomeIcon
              icon={faEllipsisVertical}
              color={colors.primary}
              style={{
                marginRight: spacings.md
              }}
              size={iconSizes.xl}
            />
          </Pressable>
        </DropdownMenuTrigger>

        <DropdownMenuContent>
          <DropdownMenuSub>
            <DropdownMenuSubTrigger
              key="language"
              textValue={t("LoginScreenLanguage", "Language")}>
              <DropdownMenu.ItemIcon ios={{ name: "globe" }} />
              <DropdownMenuItemTitle>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center"
                  }}>
                  <FontAwesomeIcon
                    icon={faGlobe}
                    color={colors.text.primary}
                    style={{
                      marginRight: spacings.sm
                    }}
                    size={iconSizes.md}
                  />
                  <Text style={[typographies.body, { flex: 1 }]}>
                    {t("LoginScreenLanguage", "Language")}
                  </Text>
                  <FontAwesomeIcon
                    icon={faChevronRight}
                    color={colors.text.primary}
                    style={{
                      marginRight: spacings.sm
                    }}
                    size={iconSizes.md}
                  />
                </View>
              </DropdownMenuItemTitle>
            </DropdownMenuSubTrigger>

            <DropdownMenuSubContent>
              {languages.map((l) => {
                return (
                  <DropdownMenuCheckboxItem
                    key={l.value}
                    textValue={l.label}
                    style={{
                      flexDirection: "row",
                      alignItems: "center"
                    }}
                    value={language === l.value}
                    onValueChange={async () => {
                      await i18n.changeLanguage(l.value);
                    }}>
                    <DropdownMenu.ItemIndicator>
                      <FontAwesomeIcon
                        icon={faCheck}
                        color={colors.text.primary}
                        style={{
                          marginRight: spacings.sm
                        }}
                        size={iconSizes.md}
                      />
                    </DropdownMenu.ItemIndicator>

                    <Text
                      style={[
                        typographies.body,
                        language !== l.value && { marginLeft: 21 }
                      ]}>
                      {l.label}
                    </Text>
                  </DropdownMenuCheckboxItem>
                );
              })}
            </DropdownMenuSubContent>
          </DropdownMenuSub>

          <DropdownMenuItem
            key="help"
            textValue={t("LoginScreenHelpCenter", "Help center")}
            onSelect={() =>
              navigation.navigate("publicHelpCenter", {
                screen: "helpCenter"
              })
            }>
            <DropdownMenu.ItemIcon ios={{ name: "questionmark.circle" }} />

            <DropdownMenuItemTitle>
              <View style={{ flexDirection: "row", alignItems: "center" }}>
                <FontAwesomeIcon
                  icon={faQuestionCircle}
                  color={colors.text.primary}
                  style={{
                    marginRight: spacings.sm
                  }}
                  size={iconSizes.md}
                />
                <Text style={typographies.body}>
                  {t("LoginScreenHelpCenter", "Help center")}
                </Text>
              </View>
            </DropdownMenuItemTitle>
          </DropdownMenuItem>

          <DropdownMenuItem
            key="privacy-policy"
            textValue={t("LoginScreenPrivacyPolicy", "Privacy policy")}
            onSelect={() => navigation.navigate("privacyPolicy")}>
            <DropdownMenu.ItemIcon
              ios={{
                name: "shield.lefthalf.fill"
              }}
            />

            <DropdownMenuItemTitle>
              <View style={{ flexDirection: "row", alignItems: "center" }}>
                <FontAwesomeIcon
                  icon={faUserShield}
                  color={colors.text.primary}
                  style={{
                    marginRight: spacings.sm
                  }}
                  size={iconSizes.md}
                />
                <Text style={typographies.body}>
                  {t("LoginScreenPrivacyPolicy", "Privacy policy")}
                </Text>
              </View>
            </DropdownMenuItemTitle>
          </DropdownMenuItem>

          <DropdownMenuItem
            key="terms-and-conditions"
            textValue={t(
              "LoginScreenTermsAndConditions",
              "Terms and conditions"
            )}
            onSelect={() => navigation.navigate("termsAndConditions")}>
            <DropdownMenu.ItemIcon ios={{ name: "doc" }} />

            <DropdownMenuItemTitle>
              <View style={{ flexDirection: "row", alignItems: "center" }}>
                <FontAwesomeIcon
                  icon={faFileContract}
                  color={colors.text.primary}
                  style={{
                    marginRight: spacings.sm
                  }}
                  size={iconSizes.md}
                />
                <Text style={typographies.body}>
                  {t("LoginScreenTermsAndConditions", "Terms and conditions")}
                </Text>
              </View>
            </DropdownMenuItemTitle>
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenuRoot>
    </View>
  );
}
