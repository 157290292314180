import { faCheck, faTimes } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import { useNavigation, useRoute } from "@react-navigation/core";
import { RouteProp } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import { format } from "date-fns";
import React from "react";
import { useTranslation } from "react-i18next";
import { StyleSheet, Text, View } from "react-native";
import { TouchableOpacity } from "react-native-gesture-handler";
import useScreenSize from "../hooks/ScreenSizeHook";
import { HomeStackParamsList } from "../navigation/HomeStackNavigation";
import { PayStackParamsList } from "../navigation/PayStackNavigation";
import { PaymentStatus } from "../services/clients/SecandaPaymentClient";
import { colors } from "../styles/Colors";
import { iconSizes, spacings } from "../styles/Constants";
import { containerStyle } from "../styles/Containers";
import { typographies } from "../styles/Fonts";

const styles = StyleSheet.create({
  textStyle: {
    color: colors.light
  }
});

export default function PaymentResultScreen() {
  const navigation = useNavigation<StackNavigationProp<HomeStackParamsList>>();
  const route = useRoute<RouteProp<PayStackParamsList, "paymentResult">>();
  const { message, color, icon } = getPaymentStatusStyle(
    route.params.resultStatus
  );
  const screenSize = useScreenSize();

  return (
    <TouchableOpacity
      style={{ flex: 1 }}
      containerStyle={{ flex: 1 }}
      onPress={() => {
        if (screenSize.large) navigation.popToTop();
        navigation.navigate("home");
      }}>
      <View
        style={[containerStyle.containerCenter, { backgroundColor: color }]}>
        <View
          style={[
            containerStyle.containerStretch,
            {
              alignItems: "center",
              justifyContent: "center"
            }
          ]}>
          {icon}

          <Text
            style={[
              typographies.heading,
              styles.textStyle,
              { marginTop: spacings.lg, marginBottom: spacings.xxl }
            ]}>
            {message}
          </Text>

          <Text style={[typographies.subtitle, styles.textStyle]}>
            {format(Date.now(), "d MMMM yyyy HH:mm:ss")}
          </Text>

          {/*TODO: Hide amount until we can retrieve it from API.
          <Text
            style={[
              styles.textStyle,
              {
                fontSize: 50,
                marginVertical: spacings.xxl
              }
            ]}>
            {humanizeCurrency(route.params.amount, route.params.currency)}
          </Text> */}
        </View>
      </View>
    </TouchableOpacity>
  );
}

function getPaymentStatusStyle(status: PaymentStatus) {
  const { t } = useTranslation();

  let message: string;
  let color: string;
  let icon = (
    <FontAwesomeIcon icon={faCheck} color={colors.light} size={iconSizes.xxl} />
  );

  switch (status) {
    case PaymentStatus.Rejected: {
      message = t("PaymentRejected", "Payment rejected");
      color = colors.danger;
      icon = (
        <FontAwesomeIcon
          icon={faTimes}
          color={colors.light}
          size={iconSizes.xxl}
        />
      );
      break;
    }
    case PaymentStatus.Paid: {
      message = t("PaymentSuccessful", "Payment successful");
      color = colors.success;
      break;
    }
    case PaymentStatus.PartiallyPaid: {
      message = t("PaymentPartiallyPaid", "Payment partially paid");
      color = colors.success;
      break;
    }
    case PaymentStatus.Expired: {
      message = t("PaymentExpired", "Payment expired");
      color = colors.warning;
      icon = (
        <FontAwesomeIcon
          icon={faTimes}
          color={colors.light}
          size={iconSizes.xxl}
        />
      );
      break;
    }
    case PaymentStatus.Aborted: {
      message = t("PaymentAborted", "Payment aborted");
      color = colors.danger;
      icon = (
        <FontAwesomeIcon
          icon={faTimes}
          color={colors.light}
          size={iconSizes.xxl}
        />
      );
      break;
    }
    default:
      message = t("PaymentSuccessful", "Payment successful");
      color = colors.success;
  }

  return { message, color, icon };
}
