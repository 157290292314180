import { useEffect, useState } from "react";
import { useAccounts } from "../services/AccountsService";
import { PersonType } from "../services/clients/PlatformClient";
import { getItemAsync, setItemAsync } from "../services/SecureStorage";

const PAYMENT_FEATURE = "payment_feature";

export default function useHasPaymentFeature(): boolean {
  const [hasPaymentEnabled, setHasPaymentEnabled] = useState<boolean>(false);

  const accountsQuery = useAccounts();

  useEffect(() => {
    refreshState();
  }, []);

  useEffect(() => {
    refreshState();
  }, [accountsQuery.data]);

  async function refreshState(): Promise<void> {
    if (accountsQuery.isLoading) {
      const isPaymentEnabled = await getItemAsync(PAYMENT_FEATURE);
      if (isPaymentEnabled) {
        setHasPaymentEnabled(isPaymentEnabled === true.toString());
      }
      return;
    }

    const isMobilePaymentAllowed = !!accountsQuery.data?.some(
      (a) =>
        a.affiliation.configuration.isMobilePaymentAllowed === true &&
        a.person?.type === PersonType.Owner
    );
    await setItemAsync(PAYMENT_FEATURE, isMobilePaymentAllowed.toString());
    setHasPaymentEnabled(isMobilePaymentAllowed);
  }

  return hasPaymentEnabled;
}
