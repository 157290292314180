import { useIsFocused, useNavigation } from "@react-navigation/core";
import { NavigationProp } from "@react-navigation/native";
import {
  BarCodeScanner,
  BarCodeScannerResult,
  PermissionStatus
} from "expo-barcode-scanner";
import { Camera } from "expo-camera";
import { default as React, useState } from "react";
import { useTranslation } from "react-i18next";
import { Alert, StyleSheet, View } from "react-native";
import { Text } from "react-native-elements";
import { CameraViewComponent } from "../components/CameraViewComponent";
import {
  PayStackParamsList,
  PaymentSelectAccountScreenProps
} from "../navigation/PayStackNavigation";
import { spacings } from "../styles/Constants";
import { containerStyle } from "../styles/Containers";
import { typographies } from "../styles/Fonts";

export default function PayScreen() {
  const { t } = useTranslation();
  const navigation = useNavigation<NavigationProp<PayStackParamsList>>();
  const [scanned, setScanned] = useState(false);
  const [permissions] = Camera.useCameraPermissions({
    get: true,
    request: true
  });
  const isFocused = useIsFocused();

  const handleBarCodeScanned = (data: string) => {
    setScanned(true);

    var qrCodeData: PaymentSelectAccountScreenProps = {
      token: data
    };

    if (qrCodeData.token == undefined) {
      Alert.alert(
        t("AlertErrorTitle", "Error"),
        t(
          "ScanQrCodeError",
          "An error occurred when scanning the Qr Code. Please, try again later."
        ),
        [
          {
            text: t("AlertOk", "OK"),
            onPress: () => setScanned(false)
          }
        ]
      );
      return;
    }
    // TODO: Adapt navigation when everything defined correctly. For the moment we skip account selection since API does not support multiple affiliation
    //navigation.navigate("paymentSelectAccount", qrCodeData);
    navigation.navigate("paymentConfirmation", qrCodeData);
    setScanned(false);
  };

  if (permissions?.status !== PermissionStatus.GRANTED) {
    return (
      <View style={containerStyle.containerCenter}>
        <Text style={[typographies.title, { marginBottom: spacings.md }]}>
          {t("CameraPermissionTitle", "Camera permission")}
        </Text>
        <Text style={[typographies.body, { textAlign: "center" }]}>
          {t(
            "NoCameraAccessMessage",
            "Unable to scan barcode without access to camera. Please enable access in the permissions settings of your device."
          )}
        </Text>
      </View>
    );
  }

  return (
    <View style={containerStyle.containerStretch}>
      {isFocused && (
        <CameraViewComponent
          onBarCodeScanned={(props: BarCodeScannerResult) =>
            scanned ? undefined : handleBarCodeScanned(props.data)
          }
          barCodeScannerSettings={{
            barCodeTypes: [BarCodeScanner.Constants.BarCodeType.qr]
          }}
          style={StyleSheet.absoluteFillObject}
        />
      )}
    </View>
  );
}
