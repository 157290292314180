import { RouteProp, useRoute } from "@react-navigation/core";
import { useNavigation } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import { default as React, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ActivityIndicator, View } from "react-native";
import { Button, Image, Text } from "react-native-elements";
import { LogoSkeleton } from "../components/LoadingSkeletonComponent";
import {
  PaymentResultScreenProps,
  PayStackParamsList
} from "../navigation/PayStackNavigation";
import { IAccountKey } from "../services/AccountsService";
import { PaymentStatus } from "../services/clients/SecandaPaymentClient";
import { humanizeCurrency } from "../services/HumanizerService";
import {
  invalidateAccount,
  invalidateAccounts,
  invalidateTransactions
} from "../services/QueryService";
import {
  getPaymentDetails,
  useAbortPayment,
  useConfirmPayment,
  usePaymentStatus,
  useSessionCheckIn
} from "../services/SecandaPaymentService";
import { toastError } from "../services/ToastService";
import {
  largePrimaryOutlineRoundedButtonStyle,
  largePrimaryRoundedButtonStyle
} from "../styles/Buttons";
import { colors } from "../styles/Colors";
import { cornerRadius, spacings } from "../styles/Constants";
import { containerStyle } from "../styles/Containers";
import { typographies } from "../styles/Fonts";

export default function PaymentConfirmationScreen() {
  const { t } = useTranslation();
  const navigation = useNavigation<StackNavigationProp<PayStackParamsList>>();
  const route =
    useRoute<RouteProp<PayStackParamsList, "paymentConfirmation">>();

  // const accountKey: IAccountKey = {
  //   identifierId: route.params.identifierId,
  //   affiliationId: route.params.affiliationId,
  //   personId: route.params.personId,
  //   accountId: route.params.accountId
  // };

  const [sessionId, setSessionId] = useState<string>();
  const [refetchInterval, setRefetchInterval] = useState(2000);

  const sessionCheckIn = useSessionCheckIn();
  const confirmPayment = useConfirmPayment();
  const abortPayment = useAbortPayment();

  const { data: paymentStatusData, isLoading: paymentStatusQueryLoading } =
    usePaymentStatus(sessionId!, {
      enabled: sessionId !== undefined,
      refetchInterval: refetchInterval,
      onSuccess: (data) => {
        if (data.interval) setRefetchInterval(data.interval);
      }
    });

  const imageHeight = 100;
  const imageWidth = 200;

  const paymentDetails = getPaymentDetails();

  useEffect(() => {
    async function checkIn() {
      try {
        const sessionSessionResponse = await sessionCheckIn.mutateAsync({
          token: route.params.token
        });
        setSessionId(sessionSessionResponse.id);
      } catch (e: any) {
        switch (e.status) {
          case 400:
            toastError(
              t("AlertErrorTitle", "Error"),
              t(
                "PaymentConfirmationScreenBadRequest",
                "An error occurred during the payment. Please check the data."
              )
            );
            break;
          case 404:
            toastError(
              t("AlertErrorTitle", "Error"),
              t(
                "PaymentConfirmationScreenNotFound",
                "The provided QR code is not valid. Please ensure you have scanned the correct QR code."
              )
            );
            break;
          default:
            toastError(
              t("AlertErrorTitle", "Error"),
              t(
                "PaymentConfirmationScreenUnknownError",
                "Internal server error"
              )
            );
            break;
        }
        navigation.popToTop();
      }
    }

    checkIn();
  }, []);

  useEffect(() => {
    if (
      paymentStatusData?.status &&
      (paymentStatusData.status === PaymentStatus.Aborted ||
        paymentStatusData.status === PaymentStatus.Paid ||
        paymentStatusData.status === PaymentStatus.PartiallyPaid ||
        paymentStatusData.status === PaymentStatus.Expired ||
        paymentStatusData.status === PaymentStatus.Rejected)
    ) {
      var paymentResult: PaymentResultScreenProps = {
        amount: paymentDetails.amount,
        currency: paymentDetails.currency,
        resultStatus: paymentStatusData.status
      };

      if (
        paymentStatusData.status === PaymentStatus.Paid ||
        paymentStatusData.status === PaymentStatus.PartiallyPaid
      ) {
        invalidateTransactions();
        invalidateAccounts();
      }
      navigation.replace("paymentResult", paymentResult);
    }
  }, [paymentStatusData]);

  if (
    sessionId === undefined ||
    paymentStatusQueryLoading ||
    paymentStatusData?.status !== PaymentStatus.ConfirmationPending
  ) {
    return (
      <View style={containerStyle.containerCenter}>
        <ActivityIndicator
          color={colors.primary}
          size="large"></ActivityIndicator>
      </View>
    );
  }

  return (
    <View
      style={[
        containerStyle.containerStretch,
        { paddingVertical: spacings.xxl }
      ]}>
      <View
        style={[
          containerStyle.containerStretch,
          {
            alignItems: "center",
            justifyContent: "center"
          }
        ]}>
        <View
          style={{
            backgroundColor: colors.light,
            width: "100%",
            borderRadius: cornerRadius.xl,
            alignItems: "center",
            paddingTop: spacings.xl
          }}>
          {paymentDetails.logo !== undefined && paymentDetails.logo !== null ? (
            <Image
              placeholderStyle={{
                backgroundColor: "transparent"
              }}
              PlaceholderContent={
                <LogoSkeleton
                  height={imageHeight}
                  width={imageWidth}></LogoSkeleton>
              }
              transition={true}
              style={{
                height: imageHeight,
                width: imageWidth,
                maxWidth: imageWidth,
                marginBottom: spacings.md
              }}
              resizeMode="contain"
              source={{
                uri: paymentDetails.logo
              }}
            />
          ) : null}

          <Text style={[typographies.subheading, { color: colors.primary }]}>
            {paymentDetails.beneficiary}
          </Text>

          <Text
            style={[
              typographies.body,
              {
                fontSize: 50,
                marginTop: spacings.md,
                marginBottom: spacings.xl
              }
            ]}>
            {humanizeCurrency(paymentDetails.amount, paymentDetails.currency)}
          </Text>
        </View>
      </View>
      <Text
        style={[
          typographies.subtitle,
          { alignSelf: "center", color: colors.primary }
        ]}>
        {t("ApprovePayment", "Do you approve the payment?")}
      </Text>
      <Button
        title={t("PayButton", "Pay")}
        buttonStyle={largePrimaryRoundedButtonStyle.button}
        containerStyle={[
          largePrimaryRoundedButtonStyle.container,
          { marginVertical: spacings.lg }
        ]}
        titleStyle={[
          largePrimaryRoundedButtonStyle.title,
          { textTransform: "uppercase" }
        ]}
        onPress={async () => await confirmPaymentAsync()}
      />
      <Button
        title={t("CancelPaymentButton", "Cancel")}
        buttonStyle={largePrimaryOutlineRoundedButtonStyle.button}
        containerStyle={largePrimaryOutlineRoundedButtonStyle.container}
        titleStyle={largePrimaryOutlineRoundedButtonStyle.title}
        onPress={async () => await abortPaymentAsync()}
      />
    </View>
  );

  async function confirmPaymentAsync() {
    try {
      if (!sessionId) {
        return;
      }

      await confirmPayment.mutateAsync(sessionId);
    } catch (e: any) {
      switch (e.status) {
        case 400:
          toastError(
            t("AlertErrorTitle", "Error"),
            t(
              "PaymentConfirmationScreenBadRequest",
              "An error occurred during the payment. Please check the data."
            )
          );
          break;
        case 401:
          toastError(
            t("AlertErrorTitle", "Error"),
            t(
              "PaymentConfirmationScreenUnauthorized",
              "The provided QR code is not authorized. Please ensure you have scanned the correct QR code."
            )
          );
          break;
        case 404:
          toastError(
            t("AlertErrorTitle", "Error"),
            t(
              "PaymentConfirmationScreenNotFound",
              "The provided QR code is not valid. Please ensure you have scanned the correct QR code."
            )
          );
          break;
        default:
          toastError(
            t("AlertErrorTitle", "Error"),
            t("PaymentConfirmationScreenUnknownError", "Internal server error")
          );
          break;
      }
    }

    async function abortPaymentAsync() {
      try {
        if (!sessionId) {
          return;
        }

        await abortPayment.mutateAsync(sessionId);
        navigation.popToTop();
      } catch (e: any) {
        switch (e.status) {
          case 400:
            toastError(
              t("AlertErrorTitle", "Error"),
              t(
                "PaymentConfirmationScreenBadRequest",
                "An error occurred during the payment. Please check the data."
              )
            );
            break;
          case 401:
            toastError(
              t("AlertErrorTitle", "Error"),
              t(
                "PaymentConfirmationScreenUnauthorized",
                "The provided QR code is not authorized. Please ensure you have scanned the correct QR code."
              )
            );
            break;
          case 404:
            toastError(
              t("AlertErrorTitle", "Error"),
              t(
                "PaymentConfirmationScreenNotFound",
                "The provided QR code is not valid. Please ensure you have scanned the correct QR code."
              )
            );
            break;
          default:
            toastError(
              t("AlertErrorTitle", "Error"),
              t(
                "PaymentConfirmationScreenUnknownError",
                "Internal server error"
              )
            );
            break;
        }
      }
    }

    async function invalidateQueries(accountKey: IAccountKey) {
      invalidateAccount(accountKey, {
        active: true,
        inactive: true
      });

      invalidateTransactions(accountKey, {
        active: true,
        inactive: true
      });
    }
  }
}
