import {
  CompositeNavigationProp,
  NavigationContainer,
  NavigatorScreenParams,
  StackActions,
  useNavigation,
  useNavigationContainerRef
} from "@react-navigation/native";
import {
  StackNavigationProp,
  TransitionPresets,
  createStackNavigator
} from "@react-navigation/stack";
import { useIsFetching } from "@tanstack/react-query";
import Constants from "expo-constants";
import { StatusBar } from "expo-status-bar";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Platform } from "react-native";
import { Button } from "react-native-elements";
import { ResultType } from "../components/ActionResultComponent";
import useDeepLinkHandlerHook from "../hooks/DeepLinkHandlerHook";
import useResetHomeNavigation from "../hooks/ResetHomeNavigationHook";
import useScreenSize from "../hooks/ScreenSizeHook";
import { useAuth } from "../providers/AuthenticationProvider";
import ActivationCodeResultScreen from "../screens/ActivationCodeResultScreen";
import ActivationCodeScreen from "../screens/ActivationCodeScreen";
import AddIdentifierResultScreen from "../screens/AddIdentifierResultScreen";
import AddIdentifierScreen from "../screens/AddIdentifierScreen";
import BlockCardResultScreen from "../screens/BlockCardResultScreen";
import CardDetailsScreen from "../screens/CardDetailsScreen";
import ChangePasswordResultScreen from "../screens/ChangePasswordResultScreen";
import ContactAffiliationScreen from "../screens/ContactAffiliationScreen";
import CreditCardPaymentScreen from "../screens/CreditCardPaymentScreen";
import EnrollPersonalDataResultScreen from "../screens/EnrollPersonalDataResultScreen";
import EnrollPersonalDataScreen from "../screens/EnrollPersonalDataScreen";
import HelpCenterScreen from "../screens/HelpCenterScreen";
import HelpSectionScreen from "../screens/HelpSectionScreen";
import IdentifierDetailScreen from "../screens/IdentifierDetailScreen";
import IdentifierNotFoundScreen from "../screens/IdentifierNotFoundScreen";
import IdentifiersScreen from "../screens/IdentifiersScreen";
import LoadAmountResultScreen from "../screens/LoadAmountResultScreen";
import LoadingScreen from "../screens/LoadingScreen";
import PrivacyPolicyScreen from "../screens/PrivacyPolicyScreen";
import RefundBalanceResultScreen from "../screens/RefundResultScreen";
import SelectAffiliationActivationScreen from "../screens/SelectAffiliationActivationScreen";
import SelectAffiliationToEnrollScreen from "../screens/SelectAffiliationToEnrollScreen";
import SendAmountResultScreen from "../screens/SendAmountResultScreen";
import TermsAndConditionsScreen from "../screens/TermsAndConditionsScreen";
import UpdateTermsAndPoliciesScreen from "../screens/UpdateTermsAndPoliciesScreen";
import { PaymentMode } from "../services/AffiliationsService";
import { logScreenView } from "../services/AnalyticsService";
import { alert } from "../services/DialogService";
import { getIdentifiers } from "../services/IdentifiersService";
import { useLegalTerms } from "../services/LegalTermsService";
import { colors, headerStyle, primaryLinkButtonStyle } from "../styles/Styles";
import AuthenticationStackScreen, {
  AuthenticationStackParamsList
} from "./AuthenticationStackNavigation";
import BottomTabNavigator, {
  BottomTabNavigatorParamsList
} from "./BottomTabMenuNavigation";
import { HomeStackParamsList } from "./HomeStackNavigation";
import { buildLinkingOptions } from "./LinkingConfiguration";
import PayStackScreen from "./PayStackNavigation";
import {
  AddIdentifierScreenProps,
  IdentifierDetailScreenProps,
  IdentifiersStackParamsList
} from "./ProfileStackNavigation";
import { SendAmountSelectionScreenProps } from "./SendStackNavigation";
import SidebarMenuNavigator, {
  DrawerNavigatorParamsList
} from "./SidebarMenuNavigation";
import UserStackScreen, { UserStackParamsList } from "./UserStackNavigation";

export type CardDetailsScreenProps = {
  identifierId: number;
  affiliationId: number;
  personId: number;
  accountId: number;
};
export type EnrollPersonalDataScreenProps = {
  affiliationId?: number;
};
export type IdentifierNotFoundScreenProps = {
  affiliationId?: number;
};
export type SelectAffiliationActivationScreenProps = {
  identifierId: number;
  affiliationId: number;
  personId: number;
  skipVisible?: boolean;
};
export type ActivationCodeScreenProps = {
  identifierId: number;
  affiliationId: number;
  personId: number;
  accountId?: number;
  skipVisible?: boolean;
};

export type ActivationCodeResultScreenProps = BaseResultScreenProps & {
  identifierId?: number;
  affiliationId?: number;
  personId?: number;
  accountId?: number;
  description: string;
};

export type TermsAndConditionsScreenProps = {
  identifierId?: number;
  affiliationId?: number;
};

export type CreditCardPaymentScreenProps = {
  identifierId: number;
  affiliationId: number;
  personId: number;
  accountId: number;
  transactionId: string;
  paymentMode: PaymentMode;
  amount: number;
  currency: string;
  pspStartUrl: string;
  successUrlCallback: string;
  cancelUrlCallback: string;
  errorUrlCallback: string;
};

export type BaseResultScreenProps = {
  type: ResultType;
};

export type SendAmountResultScreenProps = BaseResultScreenProps &
  SendAmountSelectionScreenProps & {
    amount: number;
    currency: string;
    description: string;
  };

export type LoadAmountResultScreenProps = BaseResultScreenProps & {
  amount?: number;
  currency?: string;
  identifierId?: number;
  affiliationId?: number;
  personId?: number;
  accountId?: number;
  paymentMode: PaymentMode;
};

export type AddIdentifierResultScreenProps = BaseResultScreenProps & {
  description: string;
};

export type EnrollPersonalDataResultScreenProps = BaseResultScreenProps & {
  identifierId?: number;
  affiliationId?: number;
  personId?: number;
  skipVisible?: boolean;
  selfActivationAllowed?: boolean;
  description: string;
};

export type BlockCardResultScreenProps = BaseResultScreenProps & {
  identifierId?: number;
  affiliationId?: number;
  personId?: number;
  accountId?: number;
  mediumId?: number;
  description: string;
};

export type RefundBalanceResultScreenProps = BaseResultScreenProps & {
  pendingAmount?: string;
  amountRefunded?: string;
};

export type ChangePasswordResultScreenProps = BaseResultScreenProps & {
  description: string;
};

export type EnrollIdentifiersStackParamsList = {
  identifierNotFound: IdentifierNotFoundScreenProps;
  identifiers: undefined;
  identifierDetail: IdentifierDetailScreenProps;
  addIdentifier: AddIdentifierScreenProps;
};

export type ActivationSelectAffiliationStackParamsList = {
  selectAffiliationActivation: SelectAffiliationActivationScreenProps;
  activationCode: ActivationCodeScreenProps;
};

export type EnrollSelectAffiliationStackParamsList = {
  selectAffiliationToEnroll: undefined;
  enrollPersonalData: EnrollPersonalDataScreenProps;
  enrollIdentifiersStack: NavigatorScreenParams<EnrollIdentifiersStackParamsList>;
};

export type HelpCenterStackParamsList = {
  helpCenter: undefined;
  help: undefined;
  contact: undefined;
};

export type RootStackParamsList = {
  root: NavigatorScreenParams<
    BottomTabNavigatorParamsList | DrawerNavigatorParamsList
  >;
  auth: NavigatorScreenParams<AuthenticationStackParamsList>;
  user: UserStackParamsList;
  publicHelpCenter: NavigatorScreenParams<HelpCenterStackParamsList>;
  cardDetails: CardDetailsScreenProps;
  enrollPersonalDataResult: EnrollPersonalDataResultScreenProps;
  activationCodeResult: ActivationCodeResultScreenProps;
  creditCardPayment: CreditCardPaymentScreenProps;
  termsAndConditions: TermsAndConditionsScreenProps | undefined;
  privacyPolicy: undefined;
  updateTermsAndPolicies: undefined;
  sendAmountResult: SendAmountResultScreenProps;
  addIdentifierResult: AddIdentifierResultScreenProps;
  loadAmountResult: LoadAmountResultScreenProps;
  blockCardResult: BlockCardResultScreenProps;
  refundBalanceResult: RefundBalanceResultScreenProps;
  changePasswordResult: ChangePasswordResultScreenProps;
  enrollSelectAffiliationStack: NavigatorScreenParams<EnrollSelectAffiliationStackParamsList>;
  activationSelectAffiliationStack: NavigatorScreenParams<ActivationSelectAffiliationStackParamsList>;
  payment: undefined;
};
const RootStack = createStackNavigator<RootStackParamsList>();

export default function Navigation() {
  const { isLoading, grantAccess } = useAuth();

  const initialRouteName = useMemo(() => {
    if (isLoading) return undefined;
    return grantAccess ? "root" : "auth";
  }, [isLoading, grantAccess]);

  const linkingOptions = useMemo(() => {
    return buildLinkingOptions(initialRouteName);
  }, [initialRouteName]);

  const isFetching = useIsFetching();

  const navigationRef = useNavigationContainerRef();
  const routeNameRef = useRef<string | undefined>();

  if (!linkingOptions.config?.initialRouteName) {
    return <LoadingScreen />;
  }

  return (
    <>
      <NavigationContainer
        documentTitle={{
          formatter: (options, route) =>
            `${Constants?.manifest?.name} | ${options?.title ?? route?.name}`
        }}
        theme={{
          dark: false,
          colors: {
            background: colors.background.neutral,
            primary: colors.primary,
            card: colors.background.light,
            text: colors.primary,
            border: colors.background.neutral,
            notification: colors.background.neutral
          }
        }}
        linking={linkingOptions}
        ref={navigationRef}
        onReady={() => {
          routeNameRef.current = navigationRef.getCurrentRoute()?.name;
        }}
        onStateChange={async () => {
          const previousRouteName = routeNameRef.current;
          const currentRouteName = navigationRef.getCurrentRoute()?.name;

          if (currentRouteName && previousRouteName !== currentRouteName) {
            await logScreenView(currentRouteName);
          }

          routeNameRef.current = currentRouteName;
        }}>
        <RootStackScreen></RootStackScreen>
      </NavigationContainer>

      <StatusBar
        networkActivityIndicatorVisible={isFetching > 0}
        style={Platform.OS !== "web" ? "dark" : "auto"}
      />
    </>
  );
}

function RootStackScreen() {
  const { t } = useTranslation();
  const navigation =
    useNavigation<
      CompositeNavigationProp<
        CompositeNavigationProp<
          StackNavigationProp<RootStackParamsList>,
          StackNavigationProp<HomeStackParamsList>
        >,
        StackNavigationProp<IdentifiersStackParamsList>
      >
    >();
  const { accessToken, isLoading, grantAccess, userIdentifier, localSignIn } =
    useAuth();
  const screenSize = useScreenSize();
  const legalTerms = useLegalTerms({ enabled: accessToken !== null });

  useEffect(() => {
    async function checkLocalSignIn() {
      if (accessToken && !grantAccess) {
        await localSignIn();
      }
    }

    checkLocalSignIn();
  }, [accessToken]);

  useEffect(() => {
    if (
      accessToken &&
      legalTerms.data &&
      legalTerms.data?.version < Constants.expoConfig?.extra?.legalTermsVersion
    ) {
      navigation.dispatch(StackActions.replace("updateTermsAndPolicies"));
    }
  }, [legalTerms.data]);

  const handleLink = useCallback(
    async (deepLinkUrl: string | null) => {
      if (!grantAccess || !userIdentifier) return;

      const emailIdentifierRegexMatch = deepLinkUrl?.match(
        /login\?email=([^&\/$]+)/
      );
      if (!emailIdentifierRegexMatch) return;

      const emailIdentifier = emailIdentifierRegexMatch[1];
      if (userIdentifier === emailIdentifier) return;

      const identifiers = await getIdentifiers().then((r) =>
        r.map((i) => i.value)
      );
      if (identifiers.includes(emailIdentifier)) {
        return;
      }

      alert(
        t("ThirdPartyLoginAddEmailTitle", "Add email"),
        t(
          "ThirdPartyLoginAddEmailMessage",
          "You are currently logged in with a different email than {{emailIdentifier}}. Do you want to add this email to your account?",
          { emailIdentifier: emailIdentifier }
        ),
        [
          {
            text: t("AlertConfirm", "Confirm"),
            onPress: () =>
              navigation.navigate("root", {
                screen: "profileStack",
                params: {
                  screen: "identifiersStack",
                  initial: false,
                  params: {
                    screen: "addIdentifier",
                    params: {
                      email: emailIdentifier
                    },
                    initial: false
                  }
                }
              }),
            style: "destructive"
          },
          {
            text: t("AlertCancel", "Cancel"),
            style: "cancel"
          }
        ]
      );
    },
    [grantAccess, userIdentifier]
  );

  useDeepLinkHandlerHook(handleLink, [grantAccess, userIdentifier]);

  if (isLoading) {
    return <LoadingScreen></LoadingScreen>;
  }

  return (
    <RootStack.Navigator
      screenOptions={{
        headerBackTitle: t("HeaderNavigationBackTitle", "Back"),
        headerTitleStyle: headerStyle.title,
        headerStyle: headerStyle.container,
        headerShown: false,
        cardStyle: {
          flex: 1
        }
      }}>
      {grantAccess ? (
        <>
          <RootStack.Screen
            name="root"
            component={
              screenSize.large ? SidebarMenuNavigator : BottomTabNavigator
            }
            options={{ ...TransitionPresets.ModalSlideFromBottomIOS }}
          />
          <RootStack.Screen
            name="payment"
            component={PayStackScreen}></RootStack.Screen>

          <RootStack.Screen
            name="creditCardPayment"
            component={CreditCardPaymentScreen}
            options={{
              title: t("CreditCardPaymentScreenTitle", "Credit card payment")
            }}></RootStack.Screen>

          <RootStack.Group
            screenOptions={{
              headerShown: true
            }}>
            <RootStack.Screen
              name="cardDetails"
              component={CardDetailsScreen}
              options={{
                headerStyle: headerStyle.container,
                headerTitleStyle: headerStyle.title,
                cardStyle: {
                  flex: 1
                },
                headerTitleAlign: "center",
                presentation: "modal",
                title: t("VirtualCardScreenTitle", "Virtual Card")
              }}></RootStack.Screen>
          </RootStack.Group>

          <RootStack.Group
            screenOptions={{
              headerShown: false
            }}>
            <RootStack.Screen
              name="addIdentifierResult"
              options={{
                title: t("AddIdentifierScreenTitle", "Add email")
              }}
              component={AddIdentifierResultScreen}></RootStack.Screen>
            <RootStack.Screen
              name="loadAmountResult"
              options={{
                title: t("LoadAmountSelectionScreenTitle", "Load wallet")
              }}
              component={LoadAmountResultScreen}></RootStack.Screen>
            <RootStack.Screen
              name="sendAmountResult"
              component={SendAmountResultScreen}
              options={{
                title: t("SendAmountSelectionScreenTitle", "Transfer")
              }}></RootStack.Screen>
            <RootStack.Screen
              name="enrollPersonalDataResult"
              component={EnrollPersonalDataResultScreen}
              options={{
                title: t(
                  "EnrollPersonalDataScreenTitle",
                  "Enrolment in the affiliation"
                )
              }}></RootStack.Screen>
            <RootStack.Screen
              name="activationCodeResult"
              component={ActivationCodeResultScreen}
              options={{
                title: t("ActivationCodeScreenTitle", "Account validation")
              }}></RootStack.Screen>
            <RootStack.Screen
              name="blockCardResult"
              component={BlockCardResultScreen}
              options={{
                title: t("BlockCardScreenTitle", "Block card")
              }}></RootStack.Screen>
            <RootStack.Screen
              name="refundBalanceResult"
              component={RefundBalanceResultScreen}
              options={{
                title: t("RefundBalanceScreenTitle", "Refund")
              }}></RootStack.Screen>
            <RootStack.Screen
              name="changePasswordResult"
              component={ChangePasswordResultScreen}
              options={{
                title: t("ChangePasswordScreenTitle", "Change password")
              }}></RootStack.Screen>

            <RootStack.Screen
              options={{
                headerBackTitleVisible: false,
                title: t(
                  "UpdateTermsAndPoliciesScreenTitle",
                  "Terms and conditions"
                )
              }}
              name="updateTermsAndPolicies"
              component={UpdateTermsAndPoliciesScreen}></RootStack.Screen>

            <RootStack.Screen
              name="enrollSelectAffiliationStack"
              component={EnrollSelectAffiliationStackScreen}
            />
            <RootStack.Screen
              name="activationSelectAffiliationStack"
              component={
                ActivationSelectAffiliationStackScreen
              }></RootStack.Screen>
          </RootStack.Group>
        </>
      ) : (
        <RootStack.Screen
          name="auth"
          component={AuthenticationStackScreen}
          options={{ ...TransitionPresets.ModalSlideFromBottomIOS }}
        />
      )}
      <RootStack.Screen
        name="user"
        component={UserStackScreen}></RootStack.Screen>

      <RootStack.Group screenOptions={{ headerShown: false }}>
        <RootStack.Screen
          name="publicHelpCenter"
          component={HelpCenterStackScreen}
          options={{
            title: t("HelpCenterScreenTitle", "Help Center"),
            headerBackTitleVisible: false
          }}></RootStack.Screen>
      </RootStack.Group>

      {/* Group for modal screens accessible */}
      <RootStack.Group
        screenOptions={{
          presentation: "modal",
          headerShown: true,
          cardStyle: {
            flex: 1
          }
        }}>
        <RootStack.Screen
          name="termsAndConditions"
          component={TermsAndConditionsScreen}
          options={{
            title: t("TermsAndConditionsScreenTitle", "Terms and conditions"),
            headerBackTitleVisible: false
          }}></RootStack.Screen>
        <RootStack.Screen
          name="privacyPolicy"
          component={PrivacyPolicyScreen}
          options={{
            title: t("PrivacyPolicyScreenTitle", "Privacy Policy"),
            headerBackTitleVisible: false
          }}></RootStack.Screen>
      </RootStack.Group>
    </RootStack.Navigator>
  );
}

const HelpCenterStack = createStackNavigator<HelpCenterStackParamsList>();
export function HelpCenterStackScreen() {
  const { t } = useTranslation();

  return (
    <HelpCenterStack.Navigator
      screenOptions={{
        headerBackTitle: t("HeaderNavigationBackTitle", "Back"),
        headerStyle: headerStyle.container,
        headerTitleStyle: headerStyle.title,
        cardStyle: {
          flex: 1
        }
      }}>
      <HelpCenterStack.Screen
        name="helpCenter"
        component={HelpCenterScreen}
        options={{
          title: t("HelpCenterScreenTitle", "Help Center")
        }}></HelpCenterStack.Screen>

      <HelpCenterStack.Screen
        name="help"
        component={HelpSectionScreen}
        options={{
          title: t("HelpSectionScreenTitle", "Help & Support")
        }}
      />
      <HelpCenterStack.Screen
        name="contact"
        component={ContactAffiliationScreen}
        options={{ title: t("ContactAffiliationScreenTitle", "Contact") }}
      />
    </HelpCenterStack.Navigator>
  );
}

const EnrollIdentifiersStack =
  createStackNavigator<EnrollIdentifiersStackParamsList>();
const ActivationSelectAffiliationStack =
  createStackNavigator<ActivationSelectAffiliationStackParamsList>();
const EnrollSelectAffiliationStack =
  createStackNavigator<EnrollSelectAffiliationStackParamsList>();

function EnrollIdentifiersStackScreen() {
  const { t } = useTranslation();

  return (
    <EnrollIdentifiersStack.Navigator
      screenOptions={{
        headerBackTitle: t("HeaderNavigationBackTitle", "Back"),
        headerStyle: headerStyle.container,
        headerTitleStyle: headerStyle.title,
        cardStyle: {
          flex: 1
        }
      }}>
      <EnrollIdentifiersStack.Screen
        name="identifierNotFound"
        component={IdentifierNotFoundScreen}
        options={{
          title: t("IdentifierNotFoundScreenTitle", "Enrollment")
        }}></EnrollIdentifiersStack.Screen>
      <EnrollIdentifiersStack.Screen
        name="identifiers"
        component={IdentifiersScreen}
        options={{
          title: t("IdentifiersScreenTitle", "Identifiers")
        }}></EnrollIdentifiersStack.Screen>
      <EnrollIdentifiersStack.Screen
        name="identifierDetail"
        component={IdentifierDetailScreen}
        options={{
          title: t("IdentifierDetailsScreenTitle", "Identifier")
        }}></EnrollIdentifiersStack.Screen>
      <EnrollIdentifiersStack.Screen
        name="addIdentifier"
        component={AddIdentifierScreen}
        options={{
          title: t("AddIdentifierScreenTitle", "Add email")
        }}></EnrollIdentifiersStack.Screen>
    </EnrollIdentifiersStack.Navigator>
  );
}

function ActivationSelectAffiliationStackScreen() {
  const { t } = useTranslation();
  const homeNavigation = useResetHomeNavigation();

  return (
    <ActivationSelectAffiliationStack.Navigator
      screenOptions={{
        headerBackTitle: t("HeaderNavigationBackTitle", "Back"),
        headerStyle: headerStyle.container,
        headerTitleStyle: headerStyle.title,
        cardStyle: {
          flex: 1
        }
      }}>
      <ActivationSelectAffiliationStack.Screen
        name="selectAffiliationActivation"
        component={SelectAffiliationActivationScreen}
        options={{
          title: t(
            "SelectAffiliationActivationScreenTitle",
            "Activate account"
          ),
          headerRight: () => (
            <Button
              title={t("HomeScreenSkipButton", "Skip")}
              onPress={() => homeNavigation.reset()}
              type="clear"
              titleStyle={primaryLinkButtonStyle.title}></Button>
          )
        }}></ActivationSelectAffiliationStack.Screen>
      <ActivationSelectAffiliationStack.Screen
        name="activationCode"
        component={ActivationCodeScreen}
        options={{
          title: t("ActivationCodeScreenTitle", "Account validation"),
          headerRight: () => (
            <Button
              title={t("HomeScreenSkipButton", "Skip")}
              onPress={() => homeNavigation.reset()}
              type="clear"
              titleStyle={primaryLinkButtonStyle.title}></Button>
          )
        }}></ActivationSelectAffiliationStack.Screen>
    </ActivationSelectAffiliationStack.Navigator>
  );
}

function EnrollSelectAffiliationStackScreen() {
  const { t } = useTranslation();

  return (
    <EnrollSelectAffiliationStack.Navigator
      screenOptions={{
        headerBackTitle: t("HeaderNavigationBackTitle", "Back"),
        headerStyle: headerStyle.container,
        headerTitleStyle: headerStyle.title,
        cardStyle: {
          flex: 1
        }
      }}>
      <EnrollSelectAffiliationStack.Screen
        name="selectAffiliationToEnroll"
        component={SelectAffiliationToEnrollScreen}
        options={{
          title: t("SelectAffiliationToEnrollScreenTitle", "Select affiliation")
        }}></EnrollSelectAffiliationStack.Screen>
      <EnrollSelectAffiliationStack.Screen
        name="enrollPersonalData"
        component={EnrollPersonalDataScreen}
        options={{
          title: t(
            "EnrollPersonalDataScreenTitle",
            "Enrolment in the affiliation"
          )
        }}></EnrollSelectAffiliationStack.Screen>

      <EnrollSelectAffiliationStack.Group
        screenOptions={{ headerShown: false }}>
        <EnrollSelectAffiliationStack.Screen
          name="enrollIdentifiersStack"
          component={
            EnrollIdentifiersStackScreen
          }></EnrollSelectAffiliationStack.Screen>
      </EnrollSelectAffiliationStack.Group>
    </EnrollSelectAffiliationStack.Navigator>
  );
}
